import { request } from '@/utils/request'

// 登录
export function login (parameter) {
  return request({
    url: '/login',
    method: 'post',
    data:parameter
  })
}

// 注册
export function register (parameter) {
  return request({
    url: '/register',
    method: 'post',
    data:parameter
  })
}

// 获取板块
export function getClassifyList () {
  return request({
    url: '/classify/list',
    method: 'get'
  })
}
// 新增板块
export function addClassify (parameter) {
  return request({
    url: '/classify/add',
    method: 'post',
    data: parameter
  })
}
// 编辑板块
export function updateClassify (parameter) {
  return request({
    url: '/classify/update',
    method: 'post',
    data: parameter
  })
}
// 配置板块指令
export function updatePromptClassify (parameter) {
  return request({
    url: '/classify/update/prompt',
    method: 'post',
    data: parameter
  })
}
// 删除板块
export function delClassify (parameter) {
  return request({
    url: '/classify/del',
    method: 'post',
    data: parameter
  })
}

// 导出关注
export function exportClassify (parameter) {
  return request({
    url: '/classify/export',
    method: 'get',
    responseType: 'blob',
    data: parameter
  })
}

// 获取设置
export function getSetting (parameter) {
  return request({
    url: '/setting/get',
    method: 'get',
    data: parameter
  })
}

// 更新设置
export function updateSetting (parameter) {
  return request({
    url: '/setting/update',
    method: 'post',
    data: parameter
  })
}

// 获取关注列表
export function getFollowList () {
  return request({
    url: '/follow/list',
    method: 'get'
  })
}

// 新增关注
export function addFollow (parameter) {
  return request({
    url: '/follow/add',
    method: 'post',
    data: parameter
  })
}

// 删除关注
export function delFollow (parameter) {
  return request({
    url: '/follow/del',
    method: 'post',
    data: parameter
  })
}
// 批量删除关注
export function batchDelFollow (parameter) {
  return request({
    url: '/follow/batchDel',
    method: 'post',
    data: parameter
  })
}

// 导出关注
export function exportFollow (parameter) {
  return request({
    url: '/follow/export',
    method: 'get',
    responseType: 'blob',
    data: parameter
  })
}
// 同步关注
export function syncFollow (parameter) {
  return request({
    url: '/follow/syncNickname',
    method: 'get',
    data: parameter
  })
}

// 编辑关注状态
export function updateFollow (parameter) {
  return request({
    url: '/follow/update',
    method: 'post',
    data: parameter
  })
}
// 修改用户与板块的关联
export function editFollowClassify (parameter) {
  return request({
    url: '/follow/editClassify',
    method: 'post',
    data: parameter
  })
}

// 测试
export function testFollow (parameter) {
  return request({
    url: '/follow/test',
    method: 'post',
    data:parameter
  })
}
// 测试chat
export function chattestFollow (parameter) {
  return request({
    url: '/follow/chattest',
    method: 'post',
    data:parameter
  })
}
// 汇总明细-test
export function getSummaryDetailsList (parameter) {
  return request({
    url: '/summary/details/list',
    method: 'get',
    params:parameter
  })
}

// 汇总明细
export function getSumDetailsList (parameter) {
  return request({
    url: '/summaryDetails/list',
    method: 'get',
    params:parameter
  })
}
// 汇总列表
export function getSummaryList (parameter) {
  return request({
    url: '/summary/list',
    method: 'get',
    params:parameter
  })
}

// 开放的

// 汇总明细
export function getSumDetailsOpenList (parameter) {
  return request({
    url: '/summaryDetails/list/open',
    method: 'get',
    params:parameter
  })
}
// 汇总列表
export function getSummaryOpenList (parameter) {
  return request({
    url: '/summary/list/open',
    method: 'get',
    params:parameter
  })
}

// 获取板块
export function getClassifyOpenList () {
  return request({
    url: '/classify/list/open',
    method: 'get'
  })
}